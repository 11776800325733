interface RequestTickets {
    day: number|null;
    status: string|number|number[]|null;
    user: string|number|number[]|null;
    object: string|number|number[]|null;
    department: string|number|number[]|null;
    id: number|null;
    ownerId: number|null;
    number: number|null;
    range: string|null;
}

class RequestTickets implements RequestTickets {
    day: number|null;
    status: string|number|number[]|null;
    user: string|number|number[]|null;
    apartmentId: string|number|number[]|null;
    departmentId: string|number|number[]|null;
    id: number|null;
    ownerId: number|null;
    number: number|null;
    range: string|null;

    constructor (day: number|null, status: string|number|number[]|null,
    user: string|number|number[]|null, apartmentId: string|number|number[]|null, departmentId: string|number|number[]|null,
    id: number|null, ownerId: number|null, number: number|null, range: string|null) {
        this.day = day;
        this.status = status;
        this.user = user;
        this.apartmentId = apartmentId;
        this.departmentId = departmentId;
        this.id = id;
        this.ownerId = ownerId;
        this.number = number;
        this.range = range;
    }

     map () {
       const fields: Record <string, any>  = {
           "day": (this.day === undefined || this.day === null )?null:this.day,
           "status": (this.status === undefined || this.status === ''|| this.status === null )?  "All" : this.status,
           "user": (this.user !== undefined && this.user !== null && this.user !== '' )? this.user : localStorage.getItem("user_id"),
           "apartment_id": this.apartmentId,
           "department_id": this.departmentId,
           "dept_id": this.departmentId,
           "id": this.id,
           "ownerId": this.ownerId, //ownerId !
           "number": this.number,
           "dateRange": (this.range !== null && this.range !== undefined)?this.range.replace(" to "," - "):this.range,
       }
         const result: Record <string, any>  = {};
            debugger
         for (const key in fields) {
             if ((!Array.isArray(fields[key]) && fields[key] !== undefined && fields[key] !== null) || ( Array.isArray(fields[key]) && fields[key].length>0)) {
                  result[key] = fields[key];
             }

         }
        return result ;

    }
}
// type Dict = { [key: string]: any };






export {RequestTickets}


