<template>
  <div class="row" >
    <div class="col-md-3">
      <worker :worker="worker" />
    </div>
    <div class="col-md-3">
      <!--              <i class="fas fa-business-time"></i> {{worker.workingTime}} m-->
      <working-time :worker="worker" />
    </div>
    <div class="col-md-3">
      <!--              <i class="fas fa-car"></i> {{worker.travelingTime}} m-->
      <traveling-time :worker="worker"/>
    </div>
    <div class="col-md-3">
      <!--              <i class="fas fa-tachometer-alt"></i> {{worker.loading}} %-->
      <!--          <loading :worker="worker" />-->
<!--      <vue-gauge :refid="'elementId'+ worker.id" :options="{'needleValue':85,'arcDelimiters':[50,80,100,120], 'hasNeedle':false}"></vue-gauge>-->
    </div>
  </div>
</template>

<script>
import Worker from "./Worker";
import WorkingTime from "./WorkingTime";
import TravelingTime from "./TravelingTime";
// import Loading from "./Loading";
// import VueGauge from 'vue-gauge';
export default {
  name: "LoadBalance",
  // components: {Worker, TravelingTime, WorkingTime,  VueGauge},
  components: {Worker, TravelingTime, WorkingTime},
  props: {
    worker: {
      type: Object,
      required: true
    },
  },
}
</script>

<style scoped>
</style>