<template>
  <div class="tc-note">
    <div class="row tc-note" :class="ticketClass">
      <div class="col-md-1 tc-note-body">
        <a class='fas fa-arrow-up' style='color: #01818c;' @click="moveUp(ticket.id)"></a>
        <a class='fas fa-arrow-down' style='color: #F58B3D;' @click="moveDown(ticket.id)"></a>
      </div>
      <div class="col-md-1 tc-note-body">{{ (index+1) }}</div>
      <div class="col-md-1 tc-note-body"> {{ ticket.number }} </div>
      <div class="col-md-1 tc-note-body">{{ ticket.apartmentName }}</div>
      <div class="col-md-1 tc-note-body" :title="ticket.title">
        <i :class="titleIcon" size="lg" ></i>
        <!--        <font-awesome-icon far :icon="titleIcon" size="lg" />-->
      </div>
      <div class="col-md-1 tc-note-body">{{ ticket.status }}</div>
      <div class="col-md-1 tc-note-body">{{ ticket.currentGuest }} / {{ ticket.nextGuest }}</div>
      <div class="col-md-1 tc-note-body">{{ ticket.sell_price }}</div>
        <div class="col-md-1 tc-note-body">
          <draggable class="dragArea" :list="[ticket.major]" group="people" @change="checkMajorPull"  :item-key="ticket.major.id">
            <template #item="{element}">
              <span class="multiselect-tag worker-tag is-user" v-if="(ticket.major.id !== (-1))"> <img :src="element.avatarLink">{{ element.name }}
                <i @click.prevent @mousedown.prevent.stop="removeMajor(ticket.id, ticket.major.id)"/></span>
              <span v-else>-</span>
            </template>
          </draggable>
        </div>
      <div class="col-md-1 tc-note-body">
        <draggable class="dragArea " :list="ticket.coWorkers" group="people" @change="checkPull" item-key="id">
            <template #item="{ element }">
             <span class="multiselect-tag worker-tag is-user" >
              <img :src="element.avatarLink"><i @click.prevent @mousedown.prevent.stop="removeWorker(ticket.id, element.id)"/>
             </span>
           </template>
        </draggable>
      </div>
      <div class="col-md-1 tc-note-body">
        {{ Math.floor(ticket.timePerWorker) }} /<span>{{timerForShow}}</span>
      </div>
      <div class="col-md-1 tc-note-body">
        <div class="row">
            <div class="col-md-12">
            <a @click="assignUpdate" :disabled = "canBeAssign" :class="{ disabledButton: canBeAssign }" title = 'Assign to'>
              <i class="fas fa-users actions-icon" ></i></a>
            <a @click="close(ticket.id)" :disabled = "canBeClosed" :class="{ disabledButton: canBeClosed }" title = 'Close'>
              <i class="fas fa-clipboard-check actions-icon"></i></a>
            <a @click="reopen(ticket.id)" :disabled = "canBeReopened" :class="{ disabledButton: canBeReopened }" title = 'Reopen'>
              <i class="fas fa-redo-alt actions-icon"></i></a>
              <div class="" v-if="ticket.invoiceId !== ''">
                <a @click="invoicePreview" title = 'Preview'><i class="fas fa-receipt actions-icon"></i></a>
              </div>
              <div class="" v-else>
                <a @click="modalOpenedInvoiceMe=true" title = 'Invoice Me'><i class="fas fa-money-check actions-icon" style="color: orange"></i></a>
              </div>
<!--              <div class="" v-else>-->
<!--                <a data-bs-toggle="modal" data-bs-target="#exampleModal" title = 'Invoice Me'><i class="fas fa-money-check actions-icon" style="color: orange"></i></a>-->
<!--              </div>-->
              <div class="" v-show="ticket.invoiceId !== '' && ticket.invoicePreview !== ''">
                <a @click="modalOpenedSendByEmail=true" title = 'Send by Email'><i class="fas fa-at actions-icon"></i></a>
              </div>
            <a @click="goToTicket"  title = 'View'><i class="fas fa-eye actions-icon"></i></a>
            <a @click="deleteTicket(ticket.id)" :disabled = "!canBeDeleted" :class="{ disabledButton: !canBeDeleted }" title = 'Delete'><i class="fas fa-trash actions-icon" style="color: red"></i></a>
<!--              <router-link to="ticketViewRoute"> <i class="fas fa-eye actions-icon"  title = 'View'></i></router-link>-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
          <a @click="startPause(ticket.id)" :disabled = "canBeStartedOrPaused" :class="{ disabledButton: canBeStartedOrPaused }" class="actions-icon" title = 'Start/Pause'>
            <img :src="startButtonIcon" width="20" height="20" >
<!--            <i :class="startButtonIcon" class="actions-icon" size="lg"></i>-->
<!--            <font-awesome-icon fas :icon="startButtonIcon" size="lg" />-->
<!--            <img :src="[isAdvancedActive ? 'https://cdn0.iconfinder.com/data/icons/glyphpack/29/caret-down-512.png':-->
<!--            'https://cdn0.iconfinder.com/data/icons/glyphpack/29/caret-up-512.png']" width="20" height="10">-->
          </a>
          <a @click="finish(ticket.id)" :disabled = "canBeFinished" :class="{ disabledButton: canBeFinished }" class="actions-icon" title = 'Finish' >
<!--           <i class="fas fa-stop-circle actions-icon" size="lg"></i>-->
            <img src='https://cdn4.iconfinder.com/data/icons/multimedia-35/52/stop-button-512.png' width="20" height="20" ></a>
          </div>
        </div>
      </div>

<!--      <teleport to="#modal-area">-->
<!--        <div v-if="modalOpenedInvoiceMe"  class="modal-area-invoice">-->
<!--          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--            <div class="modal-dialog">-->
<!--            <div class="modal-content">-->
<!--              <div class="modal-header" style="min-width:600px">-->
<!--                <h3 class="modal-title" id="exampleModalLabel">Action: Create Invoice</h3>-->
<!--                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--              </div>-->
<!--              <div class="modal-body">-->
<!--                <div class="row text-center">-->
<!--                  <div class="container">-->
<!--                    Are you sure you want to create invoice for ticket <a>#{{ticket.number}}</a> for <bold>{{ticket.forWhome}}</bold> in amount of-->
<!--                    <bold>{{ ticket.sell_price }} HRK</bold>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="modal-footer">-->
<!--                <a @click="invoiceMe" class="btn btn-primary" >&#10003; Create</a>-->
<!--                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">&#10007; Cancel</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </teleport>-->

      <teleport to="#modal-area">
        <div v-if="modalOpenedInvoiceMe"  class="modal-area-invoice">
          <span @click="closeModalInvoiceMe" class="close-modal-invoice">&times;</span>
          <div class="modal-header" style="min-width:600px"><h3>Action: Create Invoice</h3></div>
          <div class="modal-body">
            <div class="row text-center">
              <div class="container">
                Are you sure you want to create invoice for ticket <a>#{{ticket.number}}</a> for <bold>{{ticket.forWhome}}</bold> in amount of
                <bold>{{ ticket.sell_price }} HRK</bold>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a @click="invoiceMe" class="btn btn-primary" >&#10003; Create</a>
            <button type="button" class="btn btn-danger" @click="closeModalInvoiceMe">&#10007; Cancel</button>
          </div>
        </div>
      </teleport>

      <teleport to="#modal-area">
        <div v-if="modalOpenedSendByEmail"  class="modal-area-invoice">
          <span @click="closeModalSendByEmail" class="close-modal-invoice">&times;</span>
          <div class="modal-header" style="min-width:600px"><h3>Action: Send Invoice by Email</h3></div>
          <div class="modal-body">
            <div class="row text-center">
              <div class="container">
              Please confirm sending invoice for ticket #{{ticket.number}} to customer email
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a @click="sendByEmail" class="btn btn-primary" >&#10003; Yes</a>
            <button type="button" class="btn btn-danger" @click="closeModalSendByEmail">&#10007; No</button>
          </div>
        </div>
      </teleport>

    </div>
  </div>
</template>

<script>
// import Chat from "./Chat";
import Worker from "./Worker";
import {ActionTypes} from "@/store/actions";
import {useStore} from "@/store";
import {computed, onMounted, ref} from "vue";
import draggable from "@/vuedraggable";
import router from "../router";
import invoiceService from "@/services/invoice.servie";
// import TicketInfo from "@/components/TicketInfo";



export default {
  name: "Ticket",
  setup(props) {

    const store = useStore();
    // const startButtonIcon = ref((props.ticket.status === "Processing") ? "fas fa-pause-circle":"fas fa-play-circle");
    const startButtonIcon = ref((props.ticket.status === "Processing") ? 'https://cdn1.iconfinder.com/data/icons/line-essentials-63/20/3141-512.png':
                'https://www.freeiconspng.com/uploads/play-button-icon-png-0.png');
    // const startButtonIcon = ref((props.ticket.status === "Processing") ? "pause-circle" : "play-circle");
    const modalOpenedInvoiceMe = ref(false);
    const modalOpenedSendByEmail = ref(false);
    const closeModalInvoiceMe = () =>{
      modalOpenedInvoiceMe.value = false
    };
    const openModalInvoiceMe = () =>{
      modalOpenedInvoiceMe.value = true
    };
    const closeModalSendByEmail = () =>{
      modalOpenedSendByEmail.value = false
    };
    const openModalSendByEmail = () =>{
      modalOpenedSendByEmail.value = true
    };
    const processing = computed(() => props.ticket.status === "Processing");
    const canBeStartedOrPaused = computed(() => {
      if  (props.ticket.status === "Closed" || props.ticket.status  === "Finished")
        return true;
      return false;
    });
    // const majorInList = computed(()=> {return (props.ticket.major)?[props.ticket.major]:[]});
    const titleIcon = computed (() => {
        switch (props.ticket.title) {
          case 'MGV (cleaning between rents)' : return "fas fa-exchange-alt";
          case 'Auto Cleaning' : return "fas fa-broom";
          case 'Emergancy Cleaning' : return "fas fa-ambulance";
          case 'Postelina(linen)' : return "fas fa-bed";
          case 'General Cleaning (VGC)' : return "fas fa-door-open";
          case 'Touch Up Cleaning' : return "fas fa-hand-sparkles";
          case 'Auto Cleaning and Linen' : return "fas fa-broom";
          case 'Handy Man issues' : return "fas fa-tools";
          case 'Vrt cleaning)' : return "fas fa-leaf";
          case 'Desinfection' : return "far fa-pump-medical";
          case 'Zaverseno' : return "fas fa-door-closed";
          default :
            return "fas fa-bell";
        }
    });
    const ticketClass = computed (() => {
        const ticketClass = (props.ticket.justUpdated)?" just-updated":"";
      if (props.ticket.status === "Processing")
        return"ticket-on-processing" + ticketClass;
      if (props.ticket.status === "OnPause")
        return "ticket-on-pause" + ticketClass;
      if (props.ticket.status === "Finished")
        return "ticket-finished" + ticketClass;
      if (props.ticket.status === "Closed")
        return "ticket-closed" + ticketClass;
      if (props.ticket.status === "Deleted")
        return "ticket-deleted" + ticketClass;
      else return "ticket-on-stop" + ticketClass;
    });
    const canBeAssign = computed (() => {
       return props.ticket.status !== "Closed";
    });
    const canBeClosed = computed (() => {
       return props.ticket.status !== "Closed";
    });
    const canBeReopened = computed (() => {
       return props.ticket.status !== "Closed";
    });
    const canBeDeleted = computed (() => {
       return props.ticket.status !== "Deleted" && props.ticket.status !== "Cancel";
    });
    const canBeFinished = computed (() => {
       return !(props.ticket.status === "Processing" || props.ticket.status === "onPause");
    });
    const timer = ref(props.ticket.duration)
    const isRunning = ref(props.ticket.status==="Processing")
    const isPause = ref(props.ticket.status==="OnPause")
    let interval = setInterval(() => {
      if (!isRunning.value) {
        clearInterval(interval)
      }
      else {
        if (!isPause.value)
          timer.value +=1000
      }
    }, 1000)

    const startTimer =  () => {
      isRunning.value = true;
       interval = setInterval(() => {
             timer.value +=1000
       }, 1000)
      }
    const pauseTimer = () => {
      clearInterval(interval)
      isRunning.value = false;
      isPause.value = true;
      // pause.value =  timer.value;
    }
    const stopTimer = () => {
      clearInterval(interval)
      isRunning.value = false;
      isPause.value = true;
      // pause.value = timer.value;
    }
    const startPause = () => {
      if (props.ticket.status === "Processing") {
        store.dispatch(ActionTypes.pauseTicket, props.ticket.id);

        // startButtonIcon.value = "fas fa-play-circle";

        startButtonIcon.value = "https://www.freeiconspng.com/uploads/play-button-icon-png-0.png";
        pauseTimer()

      } else {
        store.dispatch(ActionTypes.startTicket, props.ticket.id);

        // startButtonIcon.value = "fas fa-pause-circle";

        startButtonIcon.value = "https://cdn1.iconfinder.com/data/icons/line-essentials-63/20/3141-512.png";
        startTimer()
      }
    };
    const finish = ( (id) => {
      stopTimer()
      store.dispatch(ActionTypes.finishTicket, id);
    });
    const close = id => {
      stopTimer()
      store.dispatch(ActionTypes.closeTicket, id);
    };
    const reopen = id => {
      store.dispatch(ActionTypes.reopenTicket, id);
    };
    const deleteTicket = id => {

      store.dispatch(ActionTypes.deleteTicket, id);
    };
    const moveUp = id => {
      store.dispatch(ActionTypes.moveUp, id);
    };
    const moveDown = id => { store.dispatch(ActionTypes.moveDown, id); };
    const checkPull = (event) => { store.dispatch(ActionTypes.assignWorker,{id: event.added.element.id, ticketId: props.ticket.id}) }
    const checkMajorPull = (event) => {

      if (!(props.ticket.major === null || props.ticket.major === undefined || props.ticket.major.id ===(-1))) {
        store.dispatch(ActionTypes.removeMajor,{id: props.ticket.major.id, ticketId: props.ticket.id})
      }
      store.dispatch(ActionTypes.assignMajor,{id: event.added.element.id, ticketId: props.ticket.id})
    }
    const removeMajor = (ticketId, workerId) => {

      store.dispatch(ActionTypes.removeMajor,{id: workerId, ticketId: ticketId})
    }
    const removeWorker= (ticketId, workerId) => {

      store.dispatch(ActionTypes.removeWorker,{id: workerId, ticketId: ticketId})
    }
    const hookTicket = () => {
      console.log("hook")
    }
    const processingTime = computed (()=> {
      const d = new Date(props.ticket.duration);
      return d.toISOString().slice("YYYY-MM-DDT".length,"YYYY-MM-DDT".length+8);
    })
    const timerForShow = computed(() => {
      const d = new Date(Number(Number(props.ticket.duration) +  Math.floor(timer.value)) );
      return d.toISOString().slice("YYYY-MM-DDT".length,"YYYY-MM-DDT".length+8);
    })
    const goToTicket = () => {
      router.push( {name: 'view', params: { id: props.ticket.id} })
    }
    const invoicePreview = () => {

      if (props.ticket.invoiceId !=="" && props.ticket.invoicePreview !== "") {
        window.open(props.ticket.invoicePreview, '_blank');
      }
      else {
        invoiceService.createForTicket(props.ticket.id);
      }
    }
    const invoiceMe = () => {
      debugger
      if (props.ticket.invoiceId !=="" && props.ticket.invoicePreview !== "") {
        window.open(props.ticket.invoicePreview, '_blank');
      }
      else {
        invoiceService.createForTicket(props.ticket.id);
      }
      modalOpenedInvoiceMe.value = false
    }
    const sendByEmail = () => {
      if (props.ticket.invoiceId !=="" &&props.ticket.invoicePreview !=="") {
        invoiceService.sendByEmail(props.ticket.invoiceId);
        modalOpenedSendByEmail.value = false
      }
    }
    onMounted(() => {
      if (props.ticket.status==="Processing") {
        // timer.value = props.ticket.duration
        startTimer()
      }
    });
    return {
      store,
      startPause,
      finish,
      close,
      reopen,
      deleteTicket,
      moveUp,
      moveDown,
      titleIcon,
      processing,
      ticketClass,
      startButtonIcon,
      canBeAssign,
      canBeClosed,
      canBeReopened,
      canBeDeleted,
      canBeFinished,
      canBeStartedOrPaused,
      checkPull,
      checkMajorPull,
      removeMajor,
      removeWorker,
      hookTicket,
      processingTime,
      timerForShow,
      goToTicket,
      invoicePreview,
      invoiceMe,
      sendByEmail,
      openModalInvoiceMe, closeModalInvoiceMe, modalOpenedInvoiceMe, modalOpenedSendByEmail, closeModalSendByEmail, openModalSendByEmail,
    };

  },
  components: {draggable},

  props: {
    ticket: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  methods: {
    add() {
      console.log('add')
    },
    replace() {
      console.log('replace')
    },
    checkMove(event) {
      console.log('checkMove', event)
    },
    log(event) {
      const { moved, added } = event
      if (moved) console.log('moved', moved)
      if (added) console.log('added', added, added.element)
    },
    //  moveUp(id)  {
    //
    //   this.store.dispatch(ActionTypes.moveUp, id);
    // },
  // //   start() {
  // //     this.ticket.status = "Processing";
  // //     this.$emit('ticketStart', this.ticket.id);
  // //     this.processing=true;
  // //     this.ticketClass="Processing";
  // //     // this.ticketStyle.backgroundColor="orange";
  // //     console.log("status changed to processing");
  // //   },
  // //   finish() {
  // //     this.ticket.status = "Finished";
  // //     this.$emit('ticketFinish', this.ticket.id);
  // //     this.ticketStyle.backgroundColor="pink";
  // //     // this.ticketStyle.opacity=0.5;
  // //   },
  // //   pause() {
  // //     //
  // //     this.ticket.status = "OnPause";
  // //     this.image = "./logo.png";
  // //     this.$emit('ticketPause', this.ticket.id);
  // //     this.processing=false; console.log("status changed to paused");
  // //     this.ticketStyle.backgroundColor="red";
  // //   },
  // //   reopen() {
  // //     console.log("reopen")
  // //   },
  // //   closeTicket() {
  // //     console.log("closeTicket")
  // //   },
  // //   assignUpdate() {
  // //     console.log("assignUpdate")
  // //   },
  // //   invoice() {
  // //     console.log("invoice")
  // //   },
  // //   viewTicket() {
  // //     console.log("viewTicket")
  // //   },
  // //   moveTicketUp() {
  // //     console.log("moveTicketUp")
  // //   },
  // //   moveTicketDown() {
  // //     console.log("moveTicketDown")
  // //   },
  //   isButtonDisable(name){
  //     switch(name) {
  //       case 'reopen': return this.ticket.status !== "Finished";
  //       case 'closeTicket': return this.ticket.status === "Closed";
  //       case 'start': return this.ticket.status === "Processing" || this.ticket.status === "Finished" || this.ticket.status === "Closed";
  //       case 'finish': return this.ticket.status !== "Processing" || this.ticket.status === "Finished";
  //       case 'assignUpdate': return this.ticket.status === "Processing" || this.ticket.status === "Finished" || this.ticket.status === "Closed";
  //       default: return true;
  //     }
  //   }
  },

}
</script>

<style lang="scss" scoped>
.actions-icon {
  font-size: 15px; color: #01818C; margin-right: 8px;
  cursor: pointer;
}
.disabledButton {
  opacity: 0.3;
  cursor: not-allowed;
}
.closed {
  opacity: 0.5; /* Полупрозрачность элемента */
}
.deleted {
  /*background-color: #EFF6F7;*/
  text-decoration: line-through;
  opacity: 0.3; /* Полупрозрачность элемента */
}
.processing {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  border: 0!important;
}
.ticket-on-processing {
  background-color:   #ddecee;
  opacity:   1;
}
.ticket-on-pause {
  background-color:  #e6e6e6;
  opacity:   0.8;
}
.ticket-stop {
  background-color:  whitesmoke;
  opacity:   1;
}
.ticket-finished {
  background-color:  	#fdf4e8;
  opacity:   0.8;
}
.ticket-closed {
  background-color:  	 #999999;
  opacity:   0.8;
}
.ticket-deleted {
  opacity:   0.8;
  text-decoration: line-through;
}
.out_of_schedule {
  background-color: red;
  opacity: 0.8;
}
.tc-note {
  //background-color: #f0c806;
  border-radius: 8px;
  width: 100%;
  margin: 0 0 1px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  cursor: pointer;
  font-family: 'Caveat', cursive;

  .tc-note-header {
    padding: 10px 16px 0;

    .tc-note-close {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:focus {
        box-shadow: inset 2px 3px 0px rgba(0, 0, 0, 0.8);
      }
    }

    .tc-note-close {
      float: right;
    }
  }

  .tc-note-title,
  .tc-note-body {
    outline: 0;
  }

  .tc-note-title {
    font-size: 24px;
    padding: 10px 16px;
    font-weight: bold;
  }

  .tc-note-body {
    //font-size: 20px;
    padding: 5px 0;
  }

  &:hover {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  }
}

.modal-area-invoice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color:  #ffffff;
  color: #93918f;
  border-radius: 4px;
  border: 1px solid #eee;
  z-index: 10000;
  -webkit-box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}
.close-modal-invoice {
  float: right;
  font-size: 21px;
  font-weight: bold;
  position: absolute; right:10px;
  cursor: pointer;
}
</style>
