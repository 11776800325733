import httpClient from "./http.service";

const invoiceService = {

    async invoices(from: string, until: string) {
        return httpClient.get('invoices')
    },
    async invoice(id: number) {
        return httpClient.get('invoice?id='+id)
    },
    async createForTicket(id: number) {
        return httpClient.post('invoice/create?id='+id)
    },
    async sendByEmail(id: number) {
        return httpClient.get('invoice/send?id='+id)
    },
};

export default invoiceService;
