<template>
  <div class="tc-note-wrapper" style="padding-top: 0px">
    <div class="row panel-heading navbar-item align-items-center">
      <div class="col-md-2">
<!--          <label><i class="fas fa-house-user fa-lg"></i></label>-->
        <Multiselect style="background-color: white"
            v-model='departmentId'
            mode="tags"
            placeholder='Select department(s)...'
            label='name'
            trackBy="name"
            valueProp="id"
            :searchable='true'
            :options='departments'
            @close="getTickets"
            @change="getTickets"
            @deselect="getTickets"
            @select="getTickets"
        >
        </Multiselect>
      </div>
      <div class="col-md-2">
          <Multiselect style="background-color: white"
              v-model='statusId'
              mode="tags"
              placeholder='Select status(es)...'
              label='name'
              trackBy="name"
              valueProp="id"
              :searchable='true'
              :options='statuses'
              @close="getTickets"
              @change="getTickets"
              @deselect="getTickets"
              @select="getTickets"
          >
          </Multiselect>
      </div>
      <div class="col-md-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <a class="input-group-text" @click="changeDay(-1)"><i class="fas fa-angle-double-left fa-lg" style="" title="day before"></i></a>
          </div>
          <flat-pickr v-model="dateRange"
                      :config="configCalendar"
                      class="form-control"
                      style="background-color: white; padding: 0px 0 0 10px; text-align: center"
                      placeholder="Select Date & Time"
                      @on-close="fetchNewTicketsList"></flat-pickr>
          <div class="input-group-prepend">
            <a class="input-group-text" @click="changeDay(1)"><i class="fas fa-angle-double-right fa-lg" style="" title="day after"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <Multiselect style="background-color: white"
                     v-model='apartmentId'
                     mode="tags"
                     placeholder='Select apartment(s)...'
                     label='name'
                     trackBy="name"
                     valueProp="id"
                     :searchable='true'
                     :options='apartments'
                     @close="getTickets"
                     @change="getTickets"
                     @deselect="getTickets"
                     @select="getTickets"
        >
        </Multiselect>
      </div>
      <div class="col-md-2">
        <Multiselect style="background-color: white"
                     v-model='ownerId'
                     mode="tags"
                     placeholder='Select owner(s)...'
                     label='name'
                     trackBy="name"
                     valueProp="id"
                     :searchable='true'
                     :options='owners'
                     @close="getTickets"
                     @change="getTickets"
                     @deselect="getTickets"
                     @select="getTickets"
        >
        </Multiselect>
      </div>

    </div>
      <div class="main-content" style="margin-top: 50px">
        <div class="card-custom" >
          <div class="row text-center" style="padding: 8px 14px 8px 14px;">
            <div class="col-md-2 align-items-center">
              <i class="fas fa-tachometer-alt fa-lg"></i><span class="label-loading"> Load on : 11 %</span>
            </div>
            <div class="col-md-1 " >
              <a><i class="fas fa-route" style="font-size:20px;" @click = "getMatrixes();" title="Calculate route"></i></a>
            </div>
            <div class="col-md-1 " >
              <a @click = "joinChannel"><i class="fas fa-link" style="font-size:20px;"  title="Join channel"></i></a>
            </div>
            <div class="col-md-1 " >
              <a @click = "sendMessage"><i class="far fa-comment-dots" style="font-size:20px;"  title="Message"></i></a>
            </div>
          </div>
        </div>
    <!--    Load Balance section-->
        <div class="card-custom">
        <div class="row" >
          <div class="row text-center" style="padding: 8px 14px 8px 14px;">
            <h4>Load rate of workers :</h4>
          </div>
          <div class="col-md-4" v-for="worker in workers" :worker="worker" :key="worker.id">
            <load-balance :worker="worker"/>
          </div>
        </div></div>
    <!--    End of Load Balance section-->
    <!--    Resources List Section-->
        <div class="card-custom">
          <div class="row text-center" style="padding: 8px 14px 8px 14px;">
            <div class="col-md-4">
              <div class="input-group" style="float: left">
                <a class="input-group-text" title="Create New Worker" @click="createWorker">
                  <i class="fas fa-plus fa-lg" aria-hidden="true"></i>
                </a>
                <span><input type="text"  @keyup="filterResourcesChange" class="form-control custom" placeholder="Search for worker..." v-model="resourceFilterString"></span>
              </div>
            </div>
            <div class="col-md-4" style="text-align: center"><h4>Resources list :</h4></div>
            <div class="col-md-4"><h4></h4></div>
        </div>
          <div class="row">
          <div v-if="resources.length>0">
            <draggable class="dragArea" :list="resources" :group="{ name: 'people', pull: 'clone', put: false }" :clone="cloneWorker" :move="checkMove"
                       @change="log" @ended="end" @="end" item-key="id">
              <template #item="{ element }">
                 <span class="multiselect-tag worker-tag is-user" @dblclick="cardWorker(element.id)">
                  <img :src="element.avatarLink">{{ element.name }}
                </span>
              </template>
            </draggable>
            <div class="remove"></div>
          </div>
          <div class="" v-else>There is no one worker !!</div>
          </div>
        </div>
    <!--    End of Resources List Section-->
        <div class="card-custom">
          <div class="row text-center" style="padding: 8px 14px 8px 14px;">
            <div class="col-md-4">
              <div class="input-group" style="float: left">
                <a class="input-group-text" title="Create New Ticket" @click="createTicket">
                  <i class="fas fa-plus fa-lg" aria-hidden="true"></i>
                </a>
                <span><input type="text"  @keyup="filterResourcesChange" class="form-control custom" placeholder="Search for ticket..." v-model="resourceFilterString"></span>
              </div>
            </div>
            <div class="col-md-4" style="text-align: center"><h4>Tickets list :</h4></div>
            <div class="col-md-4"><h4></h4></div>
          </div>
        <div class="row table-header tc-note-body" style="">
          <div class="col-md-1 tc-note-body">⇅</div>
          <div class="col-md-1 tc-note-body">##</div>
          <div class="col-md-1 tc-note-body">Number</div>
          <div class="col-md-1 tc-note-body">Object</div>
          <div class="col-md-1 tc-note-body">Title</div>
          <div class="col-md-1 tc-note-body">Status</div>
          <div class="col-md-1 tc-note-body">CG / NG</div>
          <div class="col-md-1 tc-note-body">Price</div>
          <div class="col-md-1 tc-note-body">Major</div>
          <div class="col-md-1 tc-note-body">Co-workers</div>
          <div class="col-md-1 tc-note-body">Time / Proc.time</div>
          <div class="col-md-1 tc-note-body">
            Actions / <router-link to="create"><i class="fas fa-plus-circle fa-lg" style="color:red" title = 'Add new ticket'></i></router-link>
          </div>
        </div>
        <div class="tc-notes">
          <div class="" v-if="tickets.length>0" :department="department">
                <ticket-droppable  v-for="(ticket, index) in tickets" :ticket="ticket"  :key="ticket.id" :index="index" style="cursor: grab"/>
          </div>
          <div class="" v-else>There is no one ticket for this day</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {useStore} from "@/store";
import {ActionTypes} from "@/store/actions";
import LoadBalance from "@/components/LoadBalance";
import draggable from "@/vuedraggable";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
// import 'bootstrap/dist/css/bootstrap.css';
import {MutationType} from "@/store/mutations";
import TicketDroppable from "@/components/TicketDroppable";
import Multiselect from '@vueform/multiselect'
import websocketService from "@/services/websocket.service";
// import VueElementLoading from "vue-element-loading";
import router from "@/router";
import dateService from "../services/dates.service";
import {RequestTickets} from "@/models/requestTickets";



export default {
  name: "TicketsList",
   setup() {

    const store = useStore();
    const user = computed( () => store.getters.getUser);
    const currentDay = computed( () => store.getters.currentDay);
    const loading = computed( () => store.getters.loading);
    const currentFormattedDay = computed( () => store.getters.currentFormattedDay);
     const department =  null;// "Housekeeping Pag";
     const resourceFilterString =  ref("");// "Housekeeping Pag";
     const tickets = computed(() => store.getters.mainFilteredTickets);
     const workers = computed(() => store.getters.filteredWorkers);
     const resources = computed(() => store.getters.filteredResources);
    const departments = computed(() => store.getters.departments);
    const statuses = computed(() => store.getters.statuses);
     const modalOpened = computed(() => store.getters.modalOpened);
     const apartments = computed( () => store.getters.apartments);
     const owners = computed( () => store.getters.owners);

     const mainFilter = reactive ({
       apartmentId: [],
       day:null,
       number:null,
       id:null,
       departmentId: [],
       ownerId: [],
       statusId: [],
       dateRange: new Date().getDate()+'.'+(new Date().getMonth()+1) +'.'+ new Date().getFullYear()+' 00:00' +' to '+ (new Date().getDate()+1)+'.'+(new Date().getMonth()+1) +'.'+ new Date().getFullYear() +' 00:00',
       start: new Date((new Date().getMonth()+1)+ '/'+(new Date().getDate()) +'/'+ new Date().getFullYear()).getTime(),
       end: new Date((new Date().getMonth()+1)+ '/'+(new Date().getDate()+1) +'/'+ new Date().getFullYear()).getTime(),
     })
     const configCalendar =  ({
       enableTime: true,
       mode: "range",
       dateFormat: "d.m.Y H:i",
       time24hr: true,
       minTime: "8:00",
       maxTime: "22:00",
       wrap: true,
       defaultDate: [new Date(mainFilter.start),new Date(mainFilter.end)]
     })
     const closeModal = () =>{
       store.commit(MutationType.changeModal,false);
     };
     const openModal = () =>{
       store.commit(MutationType.changeModal,true);
     };
     const add = () => {
       console.log('add')
     };
     const end = (event) => {

       console.log(event)
     };
     const replace = () => {
       console.log('replace')
     };
     const checkMove = (event)=> {
       console.log('checkMove', event)
       return event.relatedContext.list.filter((coWorker)=> coWorker.id === event.draggedContext.element.id).length === 0;
       // const id = event.draggedContext.element.id;
       //
       // const elem = event.relatedContext.list.filter((coWorker)=> coWorker.id === id)
       // return elem.length === 0;
     };
     const log = (event)=> {
       const { moved, added } = event
       if (moved) console.log('moved', moved)

       if (added) console.log('added', added, added.element)
     };
     const checkPull = (event) => {
       const { moved, ended } = event
       console.log(event);
       if (ended) console.log('ended', ended)
       if (moved) console.log('ended', moved)
     }
     const checkPullCoWorker = () => {
        console.log(event)
     };
    const config = {
      allowInput: true,
      wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'd.m.Y',
      // altInput: false,
      dateFormat: 'd.m.Y',
      // locale: "ru-RU", // locale for this instance only
    }
    const test = () => {
      console.log("tralala")
    }
    const changeDay = ( (direction) =>  {

      //const fromDay = (new Date(mainFilter.start + direction*24*3600000)).toISOString();
      const fromDay = (new Date(mainFilter.start + direction*24*3600000)).toLocaleString('en-EN', {year:  'numeric', month: '2-digit', day:   '2-digit'});
      //const toDay = (new Date(mainFilter.end + direction*24*3600000)).toISOString();
      const toDay = (new Date(mainFilter.end + direction*24*3600000)).toLocaleString('en-EN', {year:  'numeric', month: '2-digit', day:   '2-digit'});
      const dateRange = dateService.getRangeStringFromToWithTo([fromDay, toDay])
      const dateRangeMinus = dateService.getRangeStringFromTo([fromDay, toDay])
      store.commit(MutationType.setRangeMainFilter, dateRange);
      store.commit(MutationType.setDatesMainFilter, [(new Date(mainFilter.start + direction*24*3600000)).getTime(),(new Date(mainFilter.end + direction*24*3600000)).getTime()]);
      store.dispatch(ActionTypes.fetchTicketsOnDateRange, dateRangeMinus);
      mainFilter.dateRange = dateRange;
      mainFilter.start += direction*24*3600000;
      mainFilter.end += direction*24*3600000;
     })
     const setFilter = () => {
       const filter = store.getters.mainFilter
       if (filter) {
         mainFilter.apartmentId = filter.apartmentId;
         mainFilter.departmentId = filter.departmentId;
         mainFilter.ownerId = filter.ownerId;
         mainFilter.statusId = filter.statusId;
         mainFilter.dateRange =  new Date(filter.start).getDate()+'.'+(new Date(filter.start).getMonth()+1) +'.'+ new Date(filter.start).getFullYear()+' 00:00' +' to '+ (new Date(filter.end).getDate()+1)+'.'+(new Date(filter.end).getMonth()+1) +'.'+ new Date(filter.end).getFullYear() +' 00:00',
         mainFilter.start = filter.start;
         mainFilter.end = filter.end;
         configCalendar.defaultDate.push (new Date(filter.start))
         configCalendar.defaultDate.push (new Date(filter.end))

       }
     }
     onMounted(async () => {
      console.log('component mounted!');
      // mainFilter.loading = true
       await store.dispatch(ActionTypes.getUser);
       await store.dispatch(ActionTypes.fetchTickets);
      store.dispatch(ActionTypes.getDepartments);
      store.dispatch(ActionTypes.fetchPriorities);
      store.dispatch(ActionTypes.fetchTopics);
      store.dispatch(ActionTypes.fetchApartments);
      store.dispatch(ActionTypes.fetchOwners);
      store.dispatch(ActionTypes.getStatuses);
      store.dispatch(ActionTypes.fetchWorkersLoad,(currentDay.value/ 1000).toFixed(0));
      store.dispatch(ActionTypes.fetchResources,(currentDay.value/ 1000).toFixed(0));
      setFilter();
       // mainFilter.loading = false
    });
    const fetchNewTicketsList = (async (event) => {
      // mainFilter.loading = true
      const dateRange = dateService.getRangeStringFromTo(event)
      const fromDay = (new Date(event[0])).toLocaleString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
      const toDay = (new Date(event[1])).toLocaleString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
      store.commit(MutationType.setRangeMainFilter, {fromDay, toDay});
      debugger
      const query = new RequestTickets(mainFilter.day, mainFilter.status, mainFilter.user, mainFilter.apartmentId,
          mainFilter.departmentId, mainFilter.id, mainFilter.ownerId, mainFilter.number, mainFilter.dateRange)
      debugger
      await store.dispatch(ActionTypes.fetchTicketsForQuery, query);
      // mainFilter.loading = false
    });
    const getTickets = (() => {
      store.commit(MutationType.setMainfilter,mainFilter);
    })
    const filterResourcesChange = ((event) => {
      store.commit(MutationType.setFilterResources, resourceFilterString.value);
    })

     const connection = computed(()=> store.getters.connection)
    const joinChannel = (() => {

      const id =  websocketService.joinToChannel(connection.value, "tickets");
      console.log("Joined channel 'tickets' on websocket ws://localhost:8723/ server...");
    })
    const sendMessage =  (() => {

        const id = websocketService.sendMessage(connection.value, "Tralalala");
        console.log("Sending message 'Tralalala' on websocket ws://localhost:8723/ server...");
      })
     const createTicket = () => {
       router.push( {name: 'create' })
     }
     const createWorker = () => {
       router.push( {name: 'worker/new' })
     }
     const cardWorker = (id) => {
       router.push( {name: 'card', params: { id: id} })
     }
    return {
      store, department, tickets, departments, currentDay,
      currentFormattedDay, fetchNewTicketsList, config, user, getTickets,
      changeDay, statuses, workers, resources,
      add,  replace, checkMove,log, checkPull, test, openModal, closeModal, modalOpened, configCalendar, apartments, owners,
      joinChannel, sendMessage, createWorker, createTicket, cardWorker , filterResourcesChange, resourceFilterString, loading,...toRefs(mainFilter)

      // reactivity
    };
  },
  props() {
    return {
      day: (new Date()).getSeconds(),
    }
  },
  data() {
    return {
      // department: "Housekeeping Pag",
      // workers: workers,
      // resources: resources,
    }
  },

  components: {
    TicketDroppable,
    LoadBalance,
    draggable,
    // Worker,
    flatPickr,
    Multiselect,
    // VueElementLoading,
    // AddWorker
  },


}
</script>

<style lang="scss" >
.topnav {
  position: relative;
  z-index: 2;
  font-size: 17px;
  width: 100%;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Segoe UI",Arial,sans-serif;
}
.resources-list {
  padding-top: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(110, 117, 124, 0.6);
  width: 100%;
}
.shadow-border-grey {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(110, 117, 124, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(110, 117, 124, 0.6);
  border: 0!important;
}

.tc-notes-wrapper {
  padding-top: 0px;
  .tc-notes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
.tc-note {
  border-radius: 8px;
  width: 100%;
  margin: 0 0 1px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  //cursor: pointer;
  //font-family: 'Caveat', cursive;
  .tc-note-header {
    padding: 10px 16px 0;
    .tc-note-close {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      transition: all 0.3s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        box-shadow: inset 2px 3px 0px rgba(0, 0, 0, 0.8);
      }
    }
    .tc-note-close {
      float: right;
    }
}
}

.input-group-text {
  padding: 8px 14px 8px 14px;
}
</style>




