<template>
  <!--  <div><i class="fas fa-car"></i> {{worker.travelingTime}} m </div>-->
  <div><i class="fas fa-car"></i> {{workerTravelingTime}} m </div>
</template>

<script>
export default {
  name: "TravelingTime",
  props: {
    worker: {
      type: Object,
      required: true
    },
  },
  computed: {
    workerTravelingTime() {
      return this.worker.travelingTime;
    }
  }
}
</script>

<style scoped>
</style>