<template>
  <!--  <div><i class="fas fa-business-time"></i> {{worker.workingTime}} m </div>-->
  <div> <i class="fas fa-business-time"></i> {{workerWorkingTime}} m </div>
</template>

<script>
import {useStore} from "@/store";
import {computed} from "vue";

export default {
  name: "WorkingTime",
  props: {
    worker: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const store = useStore();
    // const time = computed( () => store.getters.getWorkingTime(props.worker.id));
    const time = 0;
    return {time}
  },
  computed: {
    workerWorkingTime() {
      return this.worker.workingTime.toFixed(1);
    }
  }
}
</script>

<style scoped>
</style>